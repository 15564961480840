<template>
  <v-card flat>
    <v-card-title>Meu perfil de Terapeuta</v-card-title>
    <v-form ref="form">
      <v-card-text class="text-center">
        <div class="d-flex align-center flex-column">
          <v-hover v-slot="{ hover }">
            <div class="photo--container" @click="openPhotoSelection">
              <v-avatar size="150">
                <v-img :src="profilePhoto" />
              </v-avatar>
              <v-fade-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out grey darken-3 v-card--reveal display-3 white--text"
                >
                  <div>
                    <v-icon color="white" size="50">add_a_photo</v-icon>
                  </div>
                </div>
              </v-fade-transition>
            </div>
          </v-hover>
          <p class="mt-3">Foto de perfil (toque para alterar)</p>
        </div>
        <input
          v-show="false"
          ref="fileInput"
          type="file"
          accept="image/.jpg,.jpeg,.png"
          @change="changedPhoto"
        />
        <v-textarea
          ref="textBio"
          v-model="settings.bio"
          rows="3"
          label="Fale sobre você"
          auto-grow
          outline
          clearable
          outlined
          class="mt-5"
          maxlength="500"
          counter
          hint="Será exibido na lisagem pública de terapeutas"
        />
        <v-switch
          v-model="settings.showToSchedule"
          label="Quero estar listado na página pública para agendamentos"
          hide-details
        />

        <v-text-field
          v-model="settings.sessionValue"
          label="Valor da Sessão"
          type="number"
          step="0.01"
          outline
          clearable
          outlined
          class="mt-5"
          prefix="R$ "
        />

        <v-textarea
          v-model="settings.additionalDetail"
          label="Detalhe Adicional (Ex: Biomagnetista, formada há x anos)"
          auto-grow
          outline
          clearable
          outlined
          class="mt-5"
          maxlength="500"
          counter
        />

        <v-text-field
          v-model="settings.abrabioNumber"
          label="Número da Abrabio"
          outline
          clearable
          outlined
          class="mt-5"
        />
        <div v-if="formedByParmagnetico" class="d-flex align-center mt-5">
          <logo-ipm style="width: 8rem" />
          <span class="ml-3">
            Selo Par Magnético (formado no Instituto Par Magnético) - Campo
            gerenciado pelos administradores
          </span>
        </div>
      </v-card-text>
    </v-form>
    <v-card-actions>
      <v-btn block color="secondary" large @click="save">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  getProfileSettings,
  saveProfileSettings,
} from "@/services/external/therapists-service";
import {
  awsPutImage,
  getProfilePreSignedUrl,
} from "@/services/external/aws-service";

export default {
  name: "TherapistSettingsProfile",
  components: { "logo-ipm": () => import("@/components/svg/LogoIPM") },
  data: () => ({
    settings: {
      bio: "",
      sessionValue: 0,
      additionalDetail: "",
      abrabioNumber: "",
    },
    preview: {
      file: {},
      url: "",
    },
  }),
  computed: {
    profilePhoto() {
      return this.preview.url || require("@/assets/no-photo.svg");
    },
    formedByParmagnetico() {
      return this.settings.formedByParmagnetico;
    },
  },
  created() {
    this.fillProfile();
  },
  methods: {
    openPhotoSelection() {
      this.$refs.fileInput.click();
    },
    changedPhoto(event) {
      const file = event.target.files[0];
      const fileSizeInKb = file.size / 1000;
      if (fileSizeInKb > 500) {
        this.$errorAlert({
          message: "Imagem muito grande. Máximo permitido: 500kb",
        });
        return;
      }
      this.preview = {
        file,
        url: URL.createObjectURL(file),
      };
    },
    async fillProfile() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await getProfileSettings();
        console.log(data);
        this.preview.url = data.photo;
        this.settings = data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async save() {
      try {
        this.$store.dispatch("loading/openDialog");
        delete this.settings.formedByParmagnetico;
        if (this.preview.file.size > 10) {
          await this.uploadPhotoAndGetUrl();
          this.updateToolbarPhoto();
        }
        await saveProfileSettings({
          ...this.settings,
          sessionValue: Number(this.settings.sessionValue),
        });
        this.$toasted.global.success({
          message: "Configurações salvas com sucesso.",
        });
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async uploadPhotoAndGetUrl() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data: awsDto } = await getProfilePreSignedUrl(
          this.preview.file.type
        );
        await awsPutImage({
          url: awsDto.url,
          key: awsDto.key,
          contentType: awsDto.contentType,
          fileImage: this.preview.file,
        });
        this.settings.photo = awsDto.resourceUrl;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    updateToolbarPhoto() {
      this.$store.commit("login/updatePhoto", this.settings.photo);
    },
    getFileExtension(fileName) {
      return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    },
  },
};
</script>

<style lang="scss">
.photo--container {
  position: relative;
  cursor: pointer;

  width: 250px;
}

.v-card--reveal {
  height: 100%;
  width: 150px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0.5;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
}
</style>
